<template>
    <div class="certificate-validator">
      <b-row class="auth-inner m-0">
        <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="6" class="px-xl-2 mx-auto">
            <b-link class="brand-logo d-flex align-items-center">
              <b-img fluid :src="geolimLogo" alt="Logo" height="40px" width="40px" />
              <h2 class="brand-text text-primary ml-1">PT GEOSERVICES</h2>
            </b-link>
            <br />
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              Certificate Validator
            </b-card-title>

            <b-form @submit.prevent="submitCertificate">
              <!-- Job Number -->
              <b-form-group label="Job Number" label-for="jobNumber">
                <b-form-input
                  id="jobNumber"
                  v-model="jobNumber"
                  placeholder="Enter Job Number"
                  required
                />
              </b-form-group>

              <!-- File Upload -->
              <b-form-group label="Upload File" label-for="file-upload">
                <b-form-file
                  id="file-upload"
                  v-model="file"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>

              <!-- CAPTCHA -->
              <b-form-group label="CAPTCHA" label-for="captcha">
              <div class="captcha-container">
                <span class="captcha-display">{{ captcha }}</span>
                <b-button @click="refreshCaptcha" class="refresh-button">
      <img src="https://www.iconpacks.net/icons/2/free-refresh-icon-3104-thumb.png" alt="Refresh" class="refresh-icon" />
    </b-button>
              </div>
              <b-form-input
                id="captcha"
                v-model="captchaInput"
                placeholder="Enter CAPTCHA"
                required
              />
            </b-form-group>

              <!-- Submit Button -->
              <b-button type="submit" variant="primary" block :disabled="invalid">
                Submit
              </b-button>
            </b-form>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </template>

  <script>
  import {
    BRow, BCol, BCardTitle, BForm, BFormGroup, BFormInput, BButton, BFormFile, BImg,
  } from 'bootstrap-vue'
  import geolimLogo from '@/assets/images/logo/geolim.png'
  import moment from 'moment'
  import axios from 'axios'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    name: 'CertificateValidator',
    components: {
      BRow,
      BCol,
      BCardTitle,
      BForm,
      BFormGroup,
      BFormInput,
      BButton,
      BFormFile,
      BImg,
    },
    data() {
      return {
        certificateId: '',
        jobNumber: '',
        file: null,
        captcha: '',
        captchaInput: '',
        captchaKey: '',
        geolimLogo,
      }
    },
    created() {
      this.fetchCaptcha()
    },
    methods: {
      async fetchCaptcha() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/invoice_service/generate_captcha`)
          this.captcha = response.data.captcha
          this.captchaKey = response.data.captcha_key
        } catch (error) {
          console.error('Error fetching CAPTCHA:', error)
        }
      },
      refreshCaptcha() {
        this.fetchCaptcha()
      },
      async submitCertificate() {
        const captchaResponse = await this.validateCaptcha()
        if (!captchaResponse.valid) {
            this.fetchCaptcha()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid CAPTCHA!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return
        }

        const formData = new FormData()
        formData.append('JobNumber', this.jobNumber)
        formData.append('time', moment().format('YYYY-MM-DD'))
        formData.append('attachment', this.file)
        const headers = {
          'Content-Type': 'multipart/form-data',
        }

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/invoice_service/certificateValidator`,
            formData,
            { headers },
          )

          if (response.status === 200 && response.data.Status === 1) {
            this.fetchCaptcha()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate Is Valid',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.fetchCaptcha()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        } catch (error) {
          console.error(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Certificate is not valid!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          console.log('finished')
        }
      },
      async validateCaptcha() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/invoice_service/validate_captcha`, {
            captcha: this.captchaInput,
            captcha_key: this.captchaKey,
          })
          return response.data
        } catch (error) {
          console.error('Error validating CAPTCHA:', error)
          return { valid: false }
        }
      },
    },
  }
  </script>
 <style scoped>
 .captcha-container {
   display: flex;
   align-items: center; /* Center align items vertically */
 }
 
 .captcha-display {
   font-weight: bold;
   font-size: 24px;
   margin-right: 10px; /* Add gap to the right */
   background-color: white; /* White background */
   padding: 10px; /* Padding for better spacing */
   border: 1px solid #ccc; /* Optional border for better visibility */
   user-select: none; /* Prevent text selection */
   margin-bottom: 10px; /* Optional left margin */
 }
 
 .refresh-button {
   background-color: transparent; /* Make background transparent */
   border: none; /* Remove border */
   cursor: pointer; /* Show pointer cursor on hover */
   padding: 0; /* Remove default button padding */
   margin-left: 5px; /* Optional left margin */
 }
 
 .refresh-button:hover {
   color: #007bff; /* Change color on hover */
 }
 .refresh-icon {
  width: 24px; /* Set width as needed */
  height: 24px; /* Set height as needed */
}
 </style>
 
  